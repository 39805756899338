.plans {
  margin-top: -4rem;
}

.plans-content-wrapper {
  width: 100%;
  max-width: 72.78rem;
  padding: 6.14rem 4.42rem;
  background: url('../../assets/images/svg/plans_bg.svg') center center no-repeat;
}

.plan-card {
  width: 100%;
  max-width: 18.92rem;
  min-height: 22.5rem;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  transition: all 0.3s;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.plan-card:hover {
  transform: scale(1.1) !important;
}

/* .plan-card-premium {
  background: linear-gradient(133.34deg, #492FB5 7.84%, #9E59CC 85.78%);
} */



.plan-selected {
  background: linear-gradient(133.34deg, #492FB5 7.84%, #9E59CC 85.78%);
  
}

.plan-selected .plan-card-header {
  background: rgba(76, 29, 180, 0.3) !important;
  color:#fff !important
}


.plan-selected .plan-card-body li {
  color:#fff
}

.plan-card-header {
  background: #F2F2F2;
  width: 100%;
  padding: 0.928rem;
  min-height: 5.285rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.plan-card-header p {
  font-size: 0.92rem;
  font-weight: 600;
  text-transform: uppercase;
}

.trial-plan-header {
  background: linear-gradient(104deg, #4DD9BD 11.73%, #3EB3AA 86.86%);
  color: #fff;
  position: relative;
}

.price-tag {
  position: absolute;
  right: 11px;
  top: -10px;
  z-index: 1;
}

.premium-plan-header {
  background: rgba(76, 29, 180, 0.3);
  color: #fff;
}

.basic-plan-header {
  background: #F2F2F2;
}

.plan-card-body {
  padding: 0.93rem 1.96rem;
  min-height: 11.21rem;
  flex: 1;
}

.plan-card-body ul li {
  font-size: 0.78rem;
  color: #3D3D3D;
  padding: 0.64rem 0;
  border-bottom: 1px solid #FBFBFB;
  display:flex;
  align-items:center;
}

.plan-card-body ul li:last-child {
  border-bottom: none;
}

.plan-card-footer {
  margin-top: 1.64rem;
  padding: 0 2.28rem 1.6rem 2.28rem;
}

.plan-card-btn {
  background: #fff;
  outline:none;
  cursor: pointer;
  font-size: 0.78rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #53ADA8;
  border: 1px solid #53ADA8;
  width: 100%;
  max-width: 14rem;
  min-height: 2.35rem;
  font-weight: 400;
}

.plan-card-premium ul li {
  border-bottom: 1px solid rgba(251, 251, 251, 0.4);
  color: #fff;
}

.premium-card-btn {
  border: none;
  color: #fff;
  background: linear-gradient(118.56deg, #50E3C2 1.81%, #00949C 110.86%);
}


.bac-btn {
  background: linear-gradient(114.53deg, #50E3C2 1.81%, #00949C 110.86%);
  border-radius: 500rem;
  font-size: 0.92rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bac-btn-banner {
  padding: 0.85rem 2.28rem;
  max-width: 17.07rem;
  height: 3.42rem;
  margin-top: 1.71rem;
}

.margin-pad-0 {
  margin:0;
  padding:0;
}

.ml-2 {
  margin-left: .5rem!important;
}


