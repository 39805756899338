.loader-overlay-conatainer {
  position: "absolute";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: "rgba(255;255;255;0.8)";
  display: "flex";
  justify-content: "center";
  align-items: "center";
  z-index: 1;
}

.loader{
  width: 60px;
  height: 50px;
  margin: 39px auto;
}
.loader > div{
  width: 5px;
  height: px;
  float: right;
  background: #000;
  margin-right: 1px;
  display: inline-block;
  position: relative;
  bottom: 0;
  margin-top: 35px;
  -webkit-animation: loading 1.5s infinite ease-in-out;
  -moz-animation: loading 1.5s infinite ease-in-out;
  -o-animation: loading 1.5s infinite ease-in-out;
  animation: loading 1.5s infinite ease-in-out;
}
.loader .box1 {
  -webkit-animation-delay: -1.5s;
  -moz-animation-delay: -1.5s;
  -o-animation-delay: -1.5s;
  animation-delay: -1.5s;
}
.loader .box2 {
  -webkit-animation-delay: -1.4s;
  -moz-animation-delay: -1.4s;
  -o-animation-delay: -1.4s;
  animation-delay: -1.4s;
}
.loader .box3 {
  -webkit-animation-delay: -1.3s;
  -moz-animation-delay: -1.3s;
  -o-animation-delay: -1.3s;
  animation-delay: -1.3s;
}
.loader .box4 {
  -webkit-animation-delay: -1.2s;
  -moz-animation-delay: -1.2s;
  -o-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.loader .box5 {
  -webkit-animation-delay: -1.1s;
  -moz-animation-delay: -1.1s;
  -o-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loader .box6 {
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s;
}
.loader .box7 {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loader .box8 {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.loader .box9 {
  -webkit-animation-delay: -0.7s;
  -moz-animation-delay: -0.7s;
  -o-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.loader .box10 {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  -o-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
@-webkit-keyframes loading {
   50% {
       height: 100%;
       margin-top: 0;
       background: #5d00b4;
   }
}
@-moz-keyframes loading {
  50% {
      height: 100%;
      margin-top: 0;
      background: #5d00b4;
  }
}
@-o-keyframes loading {
  50% {
      height: 100%;
      margin-top: 0;
      background: #5d00b4;
  }
}
@keyframes loading {
  50% {
      height: 100%;
      margin-top: 0;
      background: #5d00b4;
  }
}