.drop-zone-area {
  position: relative;
  height: 60vh;
  width: 100%;
  background: #f0f0f0;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 25vh
}

.drop-zone-success {
  background: #b2ffb2;
}

.drop-zone-error {
  background: #ffb0a9;
}

.drop-zone-footer{
  display: flex;
  justify-content: flex-end;
}

.drop-zone-footer .footer-action-button{
  margin: 0 4px
}

.btn-danger {
  background-color: rgb(225, 0, 80)!important;
}
.btn-primary {
  background-color: #2196f3 !important;
}