 * {
   box-sizing: border-box;
 }
 html, body {
   font-size: 14px

 }
body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}

.rc-slider-track {
  border: none !important;
  background: #5d00b4;
    background: -webkit-linear-gradient(
      -45deg,
      #5d00b4 0%,
      #5d00b4 11%,
      #5d00b4 11%,
      #5d00b4 30%,
      #e570e7 100%
    );
    background: -webkit-linear-gradient(
      315deg,
      #5d00b4 0%,
      #5d00b4 11%,
      #5d00b4 11%,
      #5d00b4 30%,
      #e570e7 100%
    );
    background: linear-gradient(
      135deg,
      #5d00b4 0%,
      #5d00b4 11%,
      #5d00b4 11%,
      #5d00b4 30%,
      #e570e7 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5d00b4', endColorstr='#e570e7',GradientType=1 )
}

.rc-slider-handle {
  background: #5d00b4;
  background: -webkit-linear-gradient(
    -45deg,
    #5d00b4 0%,
    #5d00b4 11%,
    #5d00b4 11%,
    #5d00b4 30%,
    #e570e7 100%
  );
  background: -webkit-linear-gradient(
    315deg,
    #5d00b4 0%,
    #5d00b4 11%,
    #5d00b4 11%,
    #5d00b4 30%,
    #e570e7 100%
  );
  background: linear-gradient(
    135deg,
    #5d00b4 0%,
    #5d00b4 11%,
    #5d00b4 11%,
    #5d00b4 30%,
    #e570e7 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5d00b4', endColorstr='#e570e7',GradientType=1 )
}



.pulseIcon {
  -webkit-animation: pulse 3s infinite;
          animation: pulse 3s infinite;
}

.pulseIcon:hover {
    -webkit-animation: none;
            animation: none;
}

.break-email {
  word-break: break-all;
}

@-webkit-keyframes pulse {
    0% {
      
        box-shadow: 0 0 0 0 rgba(152, 107, 210, 0.4);
    }

    70% {
        box-shadow: 0 0 0 50px rgba(152, 107, 210, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(152, 107, 210,0);
    }
}

@keyframes pulse {
    0% {
      
        box-shadow: 0 0 0 0 rgba(152, 107, 210, 0.4);
    }

    70% {
        box-shadow: 0 0 0 50px rgba(152, 107, 210, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(152, 107, 210,0);
    }
}
@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.1f27f800.eot);
  src:  url(/static/media/icomoon.1f27f800.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.e52991e2.ttf) format('truetype'),
    url(/static/media/icomoon.e007a96c.woff) format('woff'),
    url(/static/media/icomoon.81e6ec32.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-carpet:before {
  content: "\E923";
}
.icon-details:before {
  content: "\E909";
}
.icon-ignore:before {
  content: "\E914";
}
.icon-dollar:before {
  content: "\E922";
}
.icon-edollar:before {
  content: "\E921";
}
.icon-buildings:before {
  content: "\E91F";
}
.icon-house:before {
  content: "\E920";
}
.icon-tick1:before {
  content: "\E91E";
}
.icon-call:before {
  content: "\E918";
}
.icon-invoice_calender:before {
  content: "\E91D";
}
.icon-add:before {
  content: "\E917";
}
.icon-trash:before {
  content: "\E91C";
}
.icon-location:before {
  content: "\E919";
}
.icon-mail:before {
  content: "\E91A";
}
.icon-user:before {
  content: "\E91B";
}
.icon-online:before {
  content: "\E915";
}
.icon-offline:before {
  content: "\E916";
}
.icon-accept:before {
  content: "\E900";
}
.icon-calendar:before {
  content: "\E901";
}
.icon-cost:before {
  content: "\E902";
}
.icon-customer_listgps:before {
  content: "\E904";
}
.icon-date:before {
  content: "\E905";
}
.icon-employee_list:before {
  content: "\E906";
}
.icon-jobs:before {
  content: "\E90A";
}
.icon-overview:before {
  content: "\E90B";
}
.icon-settings:before {
  content: "\E90C";
}
.icon-time:before {
  content: "\E90D";
}
.icon-tracking:before {
  content: "\E90E";
}
.icon-address:before {
  content: "\E90F";
}
.icon-logout:before {
  content: "\E910";
}
.icon-message:before {
  content: "\E911";
}
.icon-phone:before {
  content: "\E912";
}
.icon-search:before {
  content: "\E913";
}
.icon-customer:before {
  content: "\E908";
}
.icon-px:before {
  content: "\E907";
}
.icon-jobs1:before {
  content: "\E903";
}
.icon-tick:before {
  content: "\EA10";
}

.profile-btn-avatar {
  width: 2.14rem;
  height: 2.14rem;
  border-radius: 50%;
  background: #23aea9;
  overflow: hidden;
  color: #fff;
}


.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
 -webkit-touch-callout:none;
}

.height-100 {
  height: 100%;
}

.avatar {
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  padding: 0.35rem;
}
.avatar-holder {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.avatar-image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-color: #fff;
}
.avatar-gradient-bg {
  background: -webkit-linear-gradient(224.73deg, #50E3C2 44.19%, #008DA7 108.96%);
  background: linear-gradient(225.27deg, #50E3C2 44.19%, #008DA7 108.96%);;
  color: #fff;
}

.avatar-no-border {
  padding: 0;
}

.avatar-text-lg {
  font-size: 2.5rem;
}
.profile-btn-avatar .avatar-text {
  font-size: 0.8rem;
}

.highlight-text-secondary {
  color: #9F5ACD;
}

.secondary-btn {
  background: #492fb5;
  color: #fff;
  padding: 0.7rem 1.5rem 0.7rem 1rem;
  border-radius: 7.14rem;
  display: inline-flex;
}

.secondary-btn.btn-sm svg {
  width: 1.28rem;
  height: 1.28rem;
  margin-right: 0.5rem;
}

.theme-heading {
  font-size: 3.21rem;
  font-weight: 600;
}
.grad-text {
  display: inline-block;
  background: #442eb4;
  background: -webkit-linear-gradient(-20deg, #442eb4 0%, #b062d2 100%);
  background: -webkit-linear-gradient(340deg, #442eb4 0%, #b062d2 100%);
  background: linear-gradient(110deg, #442eb4 0%, #b062d2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#442eb4', endColorstr='#b062d2', GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.avatar-text {
  width: 100%;
  height: 100%;
  background: #50E3C2ed;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-overlay-conatainer {
  position: "absolute";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: "rgba(255;255;255;0.8)";
  display: "flex";
  justify-content: "center";
  align-items: "center";
  z-index: 1;
}

.loader{
  width: 60px;
  height: 50px;
  margin: 39px auto;
}
.loader > div{
  width: 5px;
  height: px;
  float: right;
  background: #000;
  margin-right: 1px;
  display: inline-block;
  position: relative;
  bottom: 0;
  margin-top: 35px;
  -webkit-animation: loading 1.5s infinite ease-in-out;
  animation: loading 1.5s infinite ease-in-out;
}
.loader .box1 {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}
.loader .box2 {
  -webkit-animation-delay: -1.4s;
  animation-delay: -1.4s;
}
.loader .box3 {
  -webkit-animation-delay: -1.3s;
  animation-delay: -1.3s;
}
.loader .box4 {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.loader .box5 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loader .box6 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader .box7 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loader .box8 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.loader .box9 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.loader .box10 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
@-webkit-keyframes loading {
   50% {
       height: 100%;
       margin-top: 0;
       background: #5d00b4;
   }
}
@keyframes loading {
  50% {
      height: 100%;
      margin-top: 0;
      background: #5d00b4;
  }
}
.drop-zone-area {
  position: relative;
  height: 60vh;
  width: 100%;
  background: #f0f0f0;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 25vh
}

.drop-zone-success {
  background: #b2ffb2;
}

.drop-zone-error {
  background: #ffb0a9;
}

.drop-zone-footer{
  display: flex;
  justify-content: flex-end;
}

.drop-zone-footer .footer-action-button{
  margin: 0 4px
}

.btn-danger {
  background-color: rgb(225, 0, 80)!important;
}
.btn-primary {
  background-color: #2196f3 !important;
}
.plan_plans__13JK0 {
  margin-top: -4rem;
}

.plan_plans-content-wrapper__PXEim {
  width: 100%;
  max-width: 72.78rem;
  padding: 6.14rem 4.42rem;
  background: url(/static/media/plans_bg.3f6f5ddb.svg) center center no-repeat;
}

.plan_plan-card__19MSu {
  width: 100%;
  max-width: 18.92rem;
  min-height: 22.5rem;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.plan_plan-card__19MSu:hover {
  -webkit-transform: scale(1.1) !important;
          transform: scale(1.1) !important;
}

/* .plan-card-premium {
  background: linear-gradient(133.34deg, #492FB5 7.84%, #9E59CC 85.78%);
} */



.plan_plan-selected__12ZKJ {
  background: -webkit-linear-gradient(316.66deg, #492FB5 7.84%, #9E59CC 85.78%);
  background: linear-gradient(133.34deg, #492FB5 7.84%, #9E59CC 85.78%);
  
}

.plan_plan-selected__12ZKJ .plan_plan-card-header__248Tv {
  background: rgba(76, 29, 180, 0.3) !important;
  color:#fff !important
}


.plan_plan-selected__12ZKJ .plan_plan-card-body__YuvF1 li {
  color:#fff
}

.plan_plan-card-header__248Tv {
  background: #F2F2F2;
  width: 100%;
  padding: 0.928rem;
  min-height: 5.285rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.plan_plan-card-header__248Tv p {
  font-size: 0.92rem;
  font-weight: 600;
  text-transform: uppercase;
}

.plan_trial-plan-header__2AuZd {
  background: -webkit-linear-gradient(346deg, #4DD9BD 11.73%, #3EB3AA 86.86%);
  background: linear-gradient(104deg, #4DD9BD 11.73%, #3EB3AA 86.86%);
  color: #fff;
  position: relative;
}

.plan_price-tag__10ArK {
  position: absolute;
  right: 11px;
  top: -10px;
  z-index: 1;
}

.plan_premium-plan-header__2wJoj {
  background: rgba(76, 29, 180, 0.3);
  color: #fff;
}

.plan_basic-plan-header__3KdK3 {
  background: #F2F2F2;
}

.plan_plan-card-body__YuvF1 {
  padding: 0.93rem 1.96rem;
  min-height: 11.21rem;
  flex: 1 1;
}

.plan_plan-card-body__YuvF1 ul li {
  font-size: 0.78rem;
  color: #3D3D3D;
  padding: 0.64rem 0;
  border-bottom: 1px solid #FBFBFB;
  display:flex;
  align-items:center;
}

.plan_plan-card-body__YuvF1 ul li:last-child {
  border-bottom: none;
}

.plan_plan-card-footer__2Zs5_ {
  margin-top: 1.64rem;
  padding: 0 2.28rem 1.6rem 2.28rem;
}

.plan_plan-card-btn__2MHj- {
  background: #fff;
  outline:none;
  cursor: pointer;
  font-size: 0.78rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #53ADA8;
  border: 1px solid #53ADA8;
  width: 100%;
  max-width: 14rem;
  min-height: 2.35rem;
  font-weight: 400;
}

.plan_plan-card-premium__3NvO4 ul li {
  border-bottom: 1px solid rgba(251, 251, 251, 0.4);
  color: #fff;
}

.plan_premium-card-btn__1lNMx {
  border: none;
  color: #fff;
  background: -webkit-linear-gradient(331.44deg, #50E3C2 1.81%, #00949C 110.86%);
  background: linear-gradient(118.56deg, #50E3C2 1.81%, #00949C 110.86%);
}


.plan_bac-btn__1jMTV {
  background: -webkit-linear-gradient(335.47deg, #50E3C2 1.81%, #00949C 110.86%);
  background: linear-gradient(114.53deg, #50E3C2 1.81%, #00949C 110.86%);
  border-radius: 500rem;
  font-size: 0.92rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.plan_bac-btn-banner__3MSKe {
  padding: 0.85rem 2.28rem;
  max-width: 17.07rem;
  height: 3.42rem;
  margin-top: 1.71rem;
}

.plan_margin-pad-0__qHzrc {
  margin:0;
  padding:0;
}

.plan_ml-2__o-FzU {
  margin-left: .5rem!important;
}



