.profile-btn-avatar {
  width: 2.14rem;
  height: 2.14rem;
  border-radius: 50%;
  background: #23aea9;
  overflow: hidden;
  color: #fff;
}


.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
 -webkit-touch-callout:none;
}

.height-100 {
  height: 100%;
}

.avatar {
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  padding: 0.35rem;
}
.avatar-holder {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.avatar-image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-color: #fff;
}
.avatar-gradient-bg {
  background: linear-gradient(225.27deg, #50E3C2 44.19%, #008DA7 108.96%);;
  color: #fff;
}

.avatar-no-border {
  padding: 0;
}

.avatar-text-lg {
  font-size: 2.5rem;
}
.profile-btn-avatar .avatar-text {
  font-size: 0.8rem;
}

.highlight-text-secondary {
  color: #9F5ACD;
}

.secondary-btn {
  background: #492fb5;
  color: #fff;
  padding: 0.7rem 1.5rem 0.7rem 1rem;
  border-radius: 7.14rem;
  display: inline-flex;
}

.secondary-btn.btn-sm svg {
  width: 1.28rem;
  height: 1.28rem;
  margin-right: 0.5rem;
}

.theme-heading {
  font-size: 3.21rem;
  font-weight: 600;
}
.grad-text {
  display: inline-block;
  background: #442eb4;
  background: -moz-linear-gradient(-20deg, #442eb4 0%, #b062d2 100%);
  background: -webkit-linear-gradient(-20deg, #442eb4 0%, #b062d2 100%);
  background: linear-gradient(110deg, #442eb4 0%, #b062d2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#442eb4', endColorstr='#b062d2', GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.avatar-text {
  width: 100%;
  height: 100%;
  background: #50E3C2ed;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}